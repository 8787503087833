@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";

.leadGenerationLanding {
  :global {
    .lead-generation {
      max-width: 780px;
      margin: 0 auto 120px;
      color: $color-slate;
      @media only screen and (max-width: 1024px) {
        max-width: 512px;
        margin: 40px auto 40px;
      }
      &__container {
        max-width: 90pc;
        padding: 0 2pc;
        margin-right: auto;
        margin-left: auto;
      }
      &__title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 15px;
      }
      &__required-fields {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        letter-spacing: -0.45px;
        font-family: $font-regular;
        color: $color-dark-gray;
        p {
          color: $color-dark-gray;
          font-size: 14px;
        }
      }
      &__heading {
        p,
        h2,
        h3 {
          font-family: $font-medium;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 140% */
          letter-spacing: -0.4px;
          color: $color-slate;
        }
      }
      &__phoneDiv {
        display: flex;
        flex-direction: row;
        width: 100%;
      }

      &__phoneNumber {
        width: calc(100% - 171.06px);
      }
      &__phoneNumberAlternate {
        width: 100%;
      }
      &__countryIcon {
        width: 61px;
        height: 60px;
      }
      &__margin-div {
        margin-top: 30px;
      }
      &__button {
        width: 100%;
      }
      &__buttonDiv {
        display: flex;
        flex-direction: row;
        gap: 20px;
        margin: 40px 0 0 0;
      }
      &__success-box {
        display: flex;
        flex-direction: column;
        gap: 40px;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
      }
      &__success-description {
        font-family: $font-light;
        font-size: 32px;
        font-style: normal;
        font-weight: 300;
        line-height: 40px; /* 125% */
        letter-spacing: -1.6px;
        color: $slate;
        text-align: center;
        @media only screen and (max-width: 1440px) {
          font-size: 26px;
          line-height: 34px;
          letter-spacing: -1.5px;
        }
      }

      &__success-button {
        font-family: $font-light;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px; /* 144.444% */
        letter-spacing: -0.35px;
        color: $white;
      }
      &__privacyText {
        p {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
          letter-spacing: -0.55px;
          font-family: $font-regular;
          color: $color-slate;
          margin-top: 20px;
          a {
            text-decoration-line: underline;
          }
        }
      }
      &__inputfield {
        text-wrap: nowrap;
        .input-field__border {
          border-radius: 0;
        }
        margin-bottom: 10px !important;
      }
      &__countryField {
        .input-field__border {
          border-radius: 4px 0 0 4px;
          border: solid 1px $color-dark-gray;
          border-right: none;
        }
        margin-bottom: 10px !important;
      }

      &__phoneDivDisabled {
        border: solid 1px $color-dark-gray;
        border-radius: 4px;
      }

      &__verifyNumberText {
        font-family: $font-regular;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: -0.4px;
        color: $color-dark-gray;
        margin-bottom: 20px;
        margin-top: -10px;
        &.--error {
          p {
            color: $color-error-red;
            font-family: $font-regular;
          }
        }
        p {
          color: $color-dark-gray;
        }
      }

      &__getOTPLink {
        border-radius: 0 4px 4px 0;
        background-color: transparent;
        padding: 17.5px 24.1px 17.5px 24.1px;
        text-decoration-line: underline;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        letter-spacing: -0.35px;
        font-family: $font-regular;
        color: $color-dark-gray;
        white-space: nowrap;
        border: solid 1px $color-dark-gray;
        border-left: none;
        height: 60px;
        justify-content: center;
        align-items: center;
        display: flex;
        &.--active {
          color: $color-slate;
        }
      }
      @media only screen and (max-width: 1024px) {
        &__buttonDiv {
          display: flex;
          flex-direction: column;
          gap: 20px;
          margin: 30px 0 0 0;
        }
        &__title {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          flex-direction: column;
          gap: 15px;
        }
      }
      @media only screen and (max-width: 812px) {
        &__buttonDiv {
          display: flex;
          flex-direction: column;
          gap: 20px;
          margin: 30px 0 0 0;
        }
        &__title {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          flex-direction: column;
          gap: 15px;
        }
      }
      .Input_inputFieldWrapper__vj2wJ {
        .input-field {
          margin-bottom: 20px !important;
        }
      }
      @media screen and (max-width: 675px) {
        .enquiry-state {
          margin-top: 20px !important;
        }
      }
      .otp-popup__otpDigit {
        .input-field {
          margin-bottom: 0px !important;
        }
      }
      // Enquiry page sass
      &.enquiry {
        margin: 0;
        .lead-generation__phoneNumber {
          width: calc(100% - 62px);
          .lead-generation__inputfield {
            .input-field__border {
              border-radius: 0 4px 4px 0;
            }
          }
        }
      }
      @media (max-width: 575px) {
        .pr-10 {
          padding-right: 0 !important;
        }
        .pl-10 {
          padding-left: 0 !important;
        }
      }
    }
    @media only screen and (max-device-width: 575px) {
      .lead-generation {
        margin: 40px 18px 40px;
        &__buttonDiv {
          display: flex;
          flex-direction: column;
          gap: 20px;
          margin: 30px 0 0 0;
        }
        &__countryIcon {
          width: 61px;
        }
        &__phoneNumber {
          width: calc(100% - 170px);
        }
        &__title {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          flex-direction: column;
          gap: 15px;
        }
      }
    }
  }
}
