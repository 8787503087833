@import "@/styles/base/colors";
@import "@/styles/base/fonts";
.articleCard {
  :global {
    .article-card-page {
      &__eyebrow {
        color: $color-black;
        font-family: $font-regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        text-transform: uppercase;
        text-transform: uppercase;
        text-decoration: none;
        margin: 0 0 70px;
      }
      &__details {
        position: relative;
        height: 411px;
        background-color: $color-light-gray;
        list-style-type: none;
        padding: 30px;
        width: 100%;
      }
      &__title {
        color: $color-slate;
        font-family: $font-light;
        font-size: 36px;
        font-style: normal;
        font-weight: 300;
        line-height: 44px; /* 122.222% */
        letter-spacing: -1.7px;
        text-overflow: ellipsis;
        // display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      &__desc {
        color: $color-dark-gray;
        font-family: $font-light;
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        margin: 0;
      }

      &__article-image {
        height: 417px;
        max-width: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &__cta {
        position: absolute;
        bottom: 30px;
        color: $color-slate;
        font-family: $font-medium;
        font-size: 20px;
        line-height: 28px; /* 140% */
        letter-spacing: -0.4px;
        text-decoration: none;
        border-bottom: 1px solid $color-slate;
        margin-top: auto;
        cursor: pointer;
      }
      &__ctaText {
        color: $color-black;
        font-family: $font-light;
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      &__ctaIcon {
        max-width: 100%;
      }

      @media (max-width: 1023px) {
        &__details {
          height: 346px;
          padding: 30px 20px 25px;
        }

        &__eyebrow {
          margin: 0 0 60px;
        }

        &__title {
          font-size: 32px;
          line-height: 40px;
          letter-spacing: -2.2px;
        }

        &__cta {
          bottom: 25px;
          font-size: 20px;
          line-height: 28px;
          letter-spacing: -0.4px;
        }

        &__article-image {
          height: 308px;
        }
      }
    }
  }
}
