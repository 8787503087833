@import "@/styles/base/_colors";

.blogStickyHeadWrapper {
  :global {
    .blog-sticky-header {
      background-color: $color-white;
      width: 100%;
      position: fixed;
      bottom: 0;
      z-index: 9;
      left: 0;
      padding: 0;
      background-color: $color-white;
      font-size: 14px;
      line-height: 1.57;
      letter-spacing: -0.65px;
      color: $color-dark-gray;
      width: 100%;
      transition: all 0.3s ease;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.07);
      &::-webkit-scrollbar {
        display: none;
      }

      &__details-wrap {
        border-bottom: 1px solid $color-extra-light-gray;
      }

      .blog-sticky-header__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: 576px) {
          justify-content: center;
        }
        .blog-sticky-header__details {
          display: grid;
          width: 36%;
          margin: 13px 30px;

          .blog-sticky-header__brand-name {
            font-family: "Helvetica Now Text W05 Light";
            font-size: 22px;
            line-height: 32px;
            letter-spacing: -1.56px;
            color: $color-charcoal;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          .blog-sticky-header__description {
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.45px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            color: $color-dark-gray;
            font-family: "Helvetica Now Text W05 Regular";
          }
        }

        .blog-sticky-header__rating-desc {
          display: grid;
          width: 360px;
          margin-left: 20px;
          align-items: end;

          .blog-sticky-header__rating {
            height: 22px;
          }
        }

        .blog-sticky-header__color-block {
          display: flex;
          align-items: center;

          .blog-sticky-header__color {
            width: 82px;
            height: 35px;
            border-radius: 4px;
            border: 1px solid $color-dark-gray;
            margin-right: 20px;
            background-clip: content-box;
            padding: 3px;
          }
          .blog-sticky-header__color-label {
            font-family: "Helvetica Now Text W05 Medium";
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.3px;
            color: $color-dark-gray;
          }
        }

        .blog-sticky-header__right {
          display: flex;
          align-items: center;
          margin-left: auto;

          .blog-sticky-header__status {
            width: 100%;
            margin-left: 60px;
            margin-right: 60px;
            span {
              font-size: 16px;
              font-weight: 500;
              line-height: 1.5;
              letter-spacing: -0.35px;
              color: $color-charcoal;
              font-family: "Helvetica Now Text W05 Regular";
            }
          }

          .blog-sticky-header__price-block {
            display: grid;
            text-align: right;

            .blog-sticky-header__price-text {
              margin-bottom: 5px;
              font-family: "Helvetica Now Text W05 Regular";
            }

            .blog-sticky-header__price {
              font-size: 24px;
              line-height: 28px;
              letter-spacing: -0.48px;
              color: $color-slate;
              font-family: "Helvetica Now Text W05 Regular";
            }
          }

          .button {
            display: flex;
            height: 40px;
            padding: 0px 30px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            width: 395px;
            @media screen and (max-width: 576px) {
              width: 340px;
            }
          }
        }
        .blog-sticky-header__align-center {
          justify-content: center;
          padding: 10px 18px;
          margin-left: 0;
        }
      }

      .blog-sticky-header__nav-container {
        display: flex;
        padding: 20px 15px;

        .blog-sticky-header__nav-items {
          a {
            text-decoration: none;
            color: $color-dark-gray;
            font-size: 16px;
            line-height: 14px;
            letter-spacing: -0.5px;
            margin-right: 30px;
            cursor: pointer;
            font-family: "Helvetica Now Text W05 Regular";

            &.sticky-nav-active {
              color: $color-charcoal;
              letter-spacing: -0.35px;
              font-family: "Helvetica Now Text W05 Medium";
            }
          }
        }
      }

      @media screen and (max-width: 1024px) {
        .blog-sticky-header__nav-container {
          white-space: nowrap;
          overflow-x: auto;
          scroll-behavior: smooth;
          -ms-overflow-style: -ms-autohiding-scrollbar;
          -webkit-overflow-scrolling: touch;
          &::-webkit-scrollbar {
            display: none;
          }

          .blog-sticky-header__nav-items {
            margin-left: 18px;
          }
        }
      }
    }
  }
}
