@import "@/styles/base/_colors";

.shopTheRoomWrapper {
  display: contents;
  :global {
    .container-shop-the-room {
      display: flex;

      @media screen and (min-width: 1023px) {
        padding: 0 2.6rem !important;
        max-width: 20in !important;
      }

      @media screen and (min-width: 1920px) {
        padding: 0 50px !important;
        max-width: 20in !important;
      }

      @media screen and (max-width: 1023px) {
        padding: 0 32px !important;
      }

      .cmp-container {
        display: flex;
        width: 33%;
        flex-grow: 1;
        flex-wrap: wrap;
        margin-top: 45px;

        .shoptheroomcard {
          width: 30%;
          margin-right: 4.2%;

          &:nth-child(3n + 3) {
            margin-right: 0;
          }

          @media screen and (max-width: 767px) {
            width: auto;
            margin-right: 0;
          }

          @media screen and (max-width: 1023px) and (orientation: landscape) {
            width: 45%;
            margin-right: 7.46%;

            &:nth-child(3n + 3) {
              margin-right: 4.609375%;
            }

            &:nth-child(2n + 2) {
              margin-right: 0;
            }
          }
        }
      }
    }

    @mixin scroll-button-common-css {
      width: 60px;
      height: 60px;
      background: #494949;
      content: "\e92c";
      font-size: 23px;
      justify-content: center;
      align-items: center;
      opacity: 1;
      display: flex;
      font-family: icomoon, sans-serif;
      color: inherit;
    }

    .shoptheroomcard {
      .shop-the-room-card-container {
        margin: 0 0 40px 0;
        width: 30vw;

        @media screen and (min-device-width: 2559px) {
          width: auto;
        }

        @media screen and (max-width: 1023px) and (orientation: landscape) {
          width: auto;
        }

        .shop-the-room-card {
          position: relative;
          width: 100%;

          .plus {
            position: absolute;
            bottom: 8px;
            right: 8px;
            width: 14px;
            height: 14px;
            display: flex;
            align-items: center;
            cursor: pointer;

            .line {
              background: #212121;
              transition: all 0.5s ease;
            }

            @media screen and (max-width: 1023px) and (orientation: landscape) {
              bottom: 5px;
              right: 5px;
            }

            @media screen and (max-width: 1280px) {
              bottom: 4px;
              right: 8px;
            }

            @media screen and (max-width: 767px) {
              bottom: 2px;
              right: 2px;
            }
          }

          .plus .line.line-1 {
            width: 100%;
            height: 1px;
          }

          .plus .line.line-2 {
            width: 1px;
            height: 100%;
            -webkit-transform: translateX(-7px);
            transform: translateX(-7px);
          }

          .shop-the-room-card-clip {
            clip-path: polygon(100% 0, 100% 85.21%, 87.66% 100%, 0 100%, 0 0);

            &:hover {
              opacity: 0.5;
              background: #fff;
            }

            @media screen and (max-width: 1023px) and (min-width: 400px) {
              clip-path: polygon(100% 0, 100% 80%, 82% 100%, 0 100%, 0 0);
            }

            @media screen and (max-width: 375px) {
              clip-path: polygon(100% 0, 100% 75%, 80% 100%, 0 100%, 0 0);
            }

            .shop-the-room-card-hover-text {
              color: #494949;
              font-size: 36px;
              font-weight: 300;
              letter-spacing: -0.1062rem;
              line-height: 44px;
              text-align: center;
              position: absolute;
              left: 50%;
              top: 50%;
              margin-left: 0 auto;
              transform: translate(-50%, -50%);
            }
          }
        }

        .shop-card-title {
          color: #757575;
          font-size: 20px;
          letter-spacing: -0.0406rem;
          line-height: 1.75rem;
          margin: 12px 0 0 0;
          font-family: "Helvetica Now Text W05 Regular", sans-serif;

          @media screen and (max-width: 767px) {
            margin-top: 10px;
            font-size: 1rem;
            letter-spacing: -0.0344rem;
            line-height: 1.5rem;
          }

          @media screen and (max-width: 1023px) {
            color: #494949;
            font-family: "Helvetica Now Text W05 Light", sans-serif;
            font-size: 1.75rem;
            font-weight: 300;
            letter-spacing: -0.1237rem;
            line-height: 2.25rem;
          }

          @media screen and (max-width: 1023px) {
            font-size: 1rem;
            letter-spacing: -0.0344rem;
            line-height: 1.5rem;
          }
        }

        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }

      .shop-the-room-card-modal {
        .show-modal-popup {
          .modal-popup-content {
            padding: 40px 30px;
            width: calc(100% - 100px);

            @media screen and (max-width: 1024px) {
              padding: 60px 18px;
              width: 100%;
            }

            .modal-popup-body {
              padding: 0;
              display: flex;
              flex-wrap: nowrap;

              @media screen and (max-width: 1024px) {
                width: 100%;
                margin: 0;
              }

              @media screen and (max-width: 767px) and (orientation: portrait) {
                flex-wrap: wrap;
                margin: 0;
              }

              .card-modal-image-container {
                max-width: 52.8125rem;
                min-width: 52.81rem;

                @media screen and (max-width: 1023px) {
                  max-width: 21.1875rem;
                  min-width: 21.1875rem;
                }

                @media screen and (max-width: 767px) and (orientation: portrait) {
                  max-width: 100%;
                  min-width: 100%;
                }

                .image-title {
                  padding-top: 30px;
                  color: #494949;
                  width: fit-content;
                  font-family: "Helvetica Now Text W05 Medium", sans-serif;
                  font-size: 1.25rem;
                  font-weight: 500;
                  letter-spacing: 0.025rem;
                  line-height: 20px;
                  border-bottom: 1px solid #494949;

                  @media screen and (max-width: 767px) {
                    line-height: 16px;
                    font-family: "Helvetica Now Text W05 Regular", sans-serif;
                  }

                  @media screen and (max-width: 1023px) {
                    color: #494949;
                    font-family: "Helvetica Now Text W05 Light", sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: -0.32px;
                    line-height: 16px;
                    padding-top: 0;
                    margin-top: 20px;
                  }
                }
              }

              .card-modal-product-card-container {
                margin: 15px 0 0 35px;
                flex-grow: 1;

                @media screen and (max-width: 1024px) {
                  margin: 0 0 0 69px;
                }

                @media screen and (max-width: 767px) and (orientation: portrait) {
                  padding: 15px 0;
                  margin: 25px 0;
                  margin-left: 0;
                }

                @media screen and (max-width: 375px) {
                  margin-top: 30px;
                  padding: 0;
                }

                .product-cards-title {
                  color: #494949;
                  font-family: "Helvetica Now Text W05 Light", sans-serif;
                  font-size: 2.8125rem;
                  font-weight: 300;
                  letter-spacing: -0.1994rem;
                  line-height: 3.5rem;
                  margin-bottom: 40px;

                  @media screen and (max-width: 767px) {
                    font-size: 1.75rem;
                    letter-spacing: -0.1237rem;
                    line-height: 2.25rem;
                  }

                  @media screen and (max-width: 1024px) {
                    margin-bottom: 1.25rem;
                    color: #494949;
                    font-family: "Helvetica Now Text W05 Light", sans-serif;
                    font-size: 1.75rem;
                    font-weight: 300;
                    letter-spacing: -0.1237rem;
                    line-height: 2.25rem;
                  }
                }

                .product-cards-container {
                  margin-top: 46px;
                  display: flex;
                  column-gap: 20px;

                  @media screen and (max-width: 1023px) {
                    margin-top: 0;
                    justify-content: space-between;
                  }

                  @media screen and (max-width: 767px) and (orientation: landscape) {
                    column-gap: 2rem;
                  }

                  @media screen and (max-width: 767px) and (orientation: portrait) {
                    column-gap: unset;
                  }

                  .product-card {
                    min-width: 17.5rem;
                    max-width: 17.5rem;

                    @media screen and (max-width: 1023px) and (orientation: portrait) {
                      min-width: 48.5%;
                      max-width: 48.5%;
                    }

                    @media screen and (max-width: 1023px) and (orientation: landscape) {
                      min-width: 9.625rem;
                      max-width: 9.625rem;
                    }

                    &__not-display {
                      display: none;
                    }

                    .inspired-selling-module__card-container {
                      .shop-the-room-card-details {
                        .brand-name {
                          color: #757575;
                          font-family: "Helvetica Now Text W05 Regular",
                            sans-serif;
                          font-size: 0.875rem;
                          font-weight: 500;
                          letter-spacing: -0.0194rem;
                          line-height: 1.125rem;
                          padding-top: 10px;
                        }

                        .regional-brand-name {
                          color: #494949;
                          font-family: "Helvetica Now Text W05 Regular",
                            sans-serif;
                          font-size: 1.125rem;
                          letter-spacing: -0.0512rem;
                          line-height: 1.5rem;
                          padding-top: 5px;
                        }

                        .short-description {
                          color: #757575;
                          font-family: "Helvetica Now Text W05 Regular",
                            sans-serif;
                          font-size: 16px;
                          letter-spacing: -0.0344rem;
                          line-height: 22px;
                          padding-top: 5px;
                          height: 2.75rem;
                          width: 85%;

                          @media screen and (max-width: 375px) {
                            height: 3.6688rem;
                          }

                          @media screen and (max-width: 767px) {
                            height: 4.125rem;
                          }

                          @media screen and (max-width: 1024px) and (orientation: landscape) {
                            height: 4.125rem;
                          }
                        }

                        .price {
                          margin-top: 19px;
                          color: #212121;
                          font-family: "Helvetica Now Text W05 Regular",
                            sans-serif;
                          font-size: 16px;
                          font-weight: bold;
                          letter-spacing: -0.05rem;
                          line-height: 22px;
                        }
                      }
                    }
                  }
                }

                .button-container {
                  display: flex;
                  justify-content: flex-end;

                  @media screen and (max-width: 767px) {
                    justify-content: center;
                  }

                  .scroll-wrapper {
                    margin-top: 40px;

                    @media screen and (max-width: 1023px) {
                      margin-top: 30px;
                    }

                    .product-card-scroll {
                      margin-right: 20px;
                      color: #cdcdcd;

                      &::before {
                        @include scroll-button-common-css;
                      }

                      &__button-disable {
                        margin-right: 20px;
                        cursor: not-allowed;
                        color: #989898;

                        &::before {
                          @include scroll-button-common-css;
                        }
                      }

                      &__right {
                        margin-right: 0;
                        margin-left: 20px;
                        color: #cdcdcd;

                        &::before {
                          @include scroll-button-common-css;
                          transform: rotate(-180deg);
                        }

                        &__button-disable {
                          cursor: not-allowed;
                          margin-right: 0;
                          margin-left: 20px;
                          color: #989898;

                          &::before {
                            @include scroll-button-common-css;
                            transform: rotate(-180deg);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
