@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";
.wrapper {
  :global {
    .textarea__wrapper {
      position: relative;
      max-height: 150px;
      textarea {
        width: 100%;
        height: 100%;
        padding: 25px 15px;
        border-radius: 4px !important;
        border: solid 1px $color-dark-gray;
        background-color: $color-white;
        font-family: $font-regular;
        font-size: 18px;
        line-height: 1.44;
        letter-spacing: -0.7px;
        color: $color-slate;
        &:focus {
          border: 1px solid $color-slate;
        }
        &::placeholder {
          opacity: 0;
        }
        &.--with-error {
          border: solid 1px $color-error-red;
          border-radius: 4px !important;
        }
      }
      &__error-message {
        display: block;
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.38;
        letter-spacing: -0.3px;
        color: $color-error-red;
        margin-top: 10px;
      }

      textarea:placeholder-shown + label {
        cursor: text;
        transform: translate(0, 1.125rem) scale(1.5);
        transform-origin: left center;
        line-height: 26px;
        letter-spacing: -0.7px;
      }
      textarea:not(:placeholder-shown) + label,
      textarea:focus + label {
        transform: translate(0, 0) scale(1);
        transform-origin: left center;
        top: 6px;
      }
      label {
        position: absolute;
        left: 15px;
        font-family: $font-regular;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: -0.35px;
        color: $color-dark-gray;
        margin-bottom: 0;
        transition: all 0.2s;
      }
    }
  }
}
