@import "@/styles/base/_colors";

.pagination {
  :global {
    display: flex;

    flex-direction: row;

    .page-item {
      min-width: 25px;
      min-height: 25px;

      display: flex;
      gap: 30px;
      margin: 0 2px;

      transition: all 0.3s;

      &__pageNumbers {
        height: 32px;
        width: 35px;
        border: none;
        background-color: transparent;
        &:hover {
          cursor: pointer;
          background: $light-gray;
        }
      }
      &__active {
        padding: 0;

        color: $white;
        background: $slate;
      }

      &__arrow-icon {
        position: relative;
        z-index: 1;
        background-color: transparent;
        border: none;
        height: 32px;
        width: 35px;
        transform: translateY(-2px);
        transition: all 0.3s;

        &:after {
          content: "";
          width: 25px;
          height: 25px;

          position: absolute;
          z-index: -1;
          top: 56%;
          left: 50%;

          transform: translate(-50%, -50%);
        }

        &:hover {
          background: transparent;
        }
        &.disabled {
          &:hover {
            background: transparent;
          }
          cursor: not-allowed;
        }
      }
    }
  }
}
