@import "@/styles/base/_colors";
@import "@/styles/mixins/breakpoint";
.modalWrapper {
  :global {
    .modal-popup {
      display: flex;
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.7);
      opacity: 0;
      height: 100%;
      z-index: 1004;
      visibility: hidden;
      transform: scale(1.1);
      // transition not required now, code kept for later use
      // transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
      .close-button-holder {
        &.stick-close-button {
          position: sticky;
          top: 0px;
          width: 100%;
          background: $color-white;
          height: 50px;
        }
      }
      .modal-popup-content {
        visibility: hidden;
        position: relative;
        background-color: white;
        overflow-y: auto;
        .modal-popup-body {
          padding: 55px 0 50px;
        }
        margin: auto;
        width: fit-content;
        max-height: 95%;

        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 1px $color-light-gray;
          border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: $color-dark-gray;
          border-radius: 5px;
        }

        .close-button {
          position: absolute;
          right: 35px;
          top: 25px;
          cursor: pointer;
          display: block;
          float: right;
          z-index: 1;
          width: 17px;
          height: 30px;
          img {
            width: 16.6px;
          }
        }
        .modal-popup-body {
          margin: 0;
        }
      }

      &.show-modal-popup {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
        // transition not required now, code kept for later use
        // transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
        .modal-popup-content {
          visibility: visible;
        }
        &.confirmation {
          .auth-modal-confirm {
            padding: 0;
          }
          .modal-popup-content {
            width: 500px;
            @media screen and (max-width: 576px) {
              width: 339px;
            }
            @media screen and (max-width: 812px) {
              width: 416px;
            }
          }
          .modal-popup-body {
            padding: 55px 50px 50px;
            @media screen and (max-width: 812px) {
              padding: 45px 30px 30px;
            }
          }
          .otp-popup {
            &__title {
              padding-bottom: 40px;
              color: $color-slate;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              letter-spacing: 1.818px;
              text-transform: uppercase;
              text-align: center;
            }
            &__small-case-title {
              padding-bottom: 40px;
              color: $color-slate;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              letter-spacing: 1.818px;
              text-align: center;
            }
            &__buttonDiv {
              display: flex;
              gap: 20px;
              justify-content: center;
              @media screen and (max-width: 576px) {
                display: unset;
              }
            }
            &__button {
              width: 190px;
              height: 50px;

              @media screen and (max-width: 812px) {
                width: 198px;
              }
              @media screen and (max-width: 576px) {
                width: 100%;
              }
              &.black {
                @media screen and (max-width: 576px) {
                  margin-bottom: 20px;
                }
              }
            }
          }
        }

        @media screen and (max-width: 860px) {
          &.confirmation .modal-popup-content {
            margin: auto;
            width: 476px;
            min-height: auto !important;
            height: auto !important;
          }
        }
        @media screen and (max-width: 576px) {
          &.confirmation .modal-popup-content {
            margin: auto;
            width: 339px;
            .auth-modal__title {
              width: auto;
            }
          }
        }
      }
      &.server-error-popup {
        background-color: rgba(0, 0, 0, 1);
        .modal-popup-content {
          .close-button {
            display: none;
          }
          .modal-popup-body {
            padding: 55px 50px 50px;
            .auth-modal__title {
              text-transform: none;
              h2 {
                text-transform: uppercase;
              }
            }
          }
          .auth-modal__buttonDiv {
            flex-direction: column;
            gap: inherit;
          }
        }
      }
    }
    @media only screen and (max-width: 812px) {
      .modal-popup {
        .modal-popup-content {
          margin: none !important;
          min-height: 100vh;
          width: 100%;
          .close-button {
            top: 15px;
            right: 32px;
            width: 17px;
            height: 30px;
            img {
              width: 17px;
              height: 30px;
            }
          }
          .modal-popup-body {
            padding: 45px 32px 40px;
          }
        }
      }
    }
    @media screen and (max-width: 576px) {
      .modal-popup {
        .modal-popup-content {
          margin: none !important;
          min-height: 100vh;
          width: 100%;
          .modal-popup-body {
            padding: 33px 18px 40px;
          }
        }
      }
    }
  }
}
