@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";

.accordionWrapper {
  :global {
    .collapsible-accordion {
      .Collapsible {
        border-bottom: 1px solid $color-light-gray;

        &.collapsible--open {
          .Collapsible__contentInner {
            opacity: 1;
            visibility: visible;
          }
        }

        &__trigger {
          width: 100%;
          display: block;
          font-family: $font-medium;
          font-size: 16px;
          line-height: 1.5;
          letter-spacing: -0.35px;
          color: $color-charcoal;
          padding: 20px 0px;
          cursor: pointer;

          .collapsible__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 4px;

            .plus {
              width: 20px;
              height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;

              .line {
                background: $color-charcoal;
                transition: all 0.5s ease;

                &.line-1 {
                  width: 100%;
                  height: 1px;
                }

                &.line-2 {
                  width: 1px;
                  height: 100%;
                  transform: translateX(-10px);
                }
              }
            }
          }

          &.collapsible__trigger--open {
            .plus {
              .line {
                &.line-2 {
                  height: 0;
                }
              }
            }
          }
        }

        &__contentInner {
          opacity: 0;
          transition: all 0.3s ease-in-out;
          visibility: hidden;
        }
      }
    }
  }
}
