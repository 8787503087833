@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";
@import "@/styles/mixins/breakpoint";

.wrapper {
  :global {
    .select-dropdown {
      position: relative;

      @media not all and (min-resolution: 0.001dpcm) {
        input:focus,
        select:focus,
        textarea:focus {
          outline: none !important;
        }
      }

      &__field {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        border-radius: 4px;
        border: solid 1px $color-dark-gray;
        background-color: $color-white;
        gap: 10px;
        &.--with-error {
          border: solid 1px $color-error-red;
        }
        &:focus {
          border: 1px solid $color-slate;
        }
      }
      &__display {
        display: flex;
        flex-direction: column;
        width: 95%;
      }
      &__placeholder {
        display: block;
        font-family: $font-regular;
        font-size: 18px;
        line-height: 1.44;
        letter-spacing: -0.7px;
        color: $color-dark-gray;
        transition: all 0.5s ease;
        &.--label {
          position: absolute;
          top: 5px;
          font-size: 12px;
          line-height: 1.67;
          letter-spacing: -0.35px;
        }
      }
      &__data-value {
        width: 100%;
        display: flex;
        flex-flow: column-reverse;
        overflow: hidden;
        transition: all 0.2s;
        touch-action: manipulation;

        label,
        input {
          transition: all 0.2s;
          touch-action: manipulation;
        }

        input {
          background: none;
          padding: 0;
          border: none;
          font-family: $font-regular;
          font-size: 18px;
          line-height: 26px;
          letter-spacing: -0.5px;
          color: $color-slate;
          cursor: text;
          z-index: 1;

          &:focus {
            outline: 0;
            border: none;
          }
        }

        label {
          font-family: $font-regular;
          font-size: 12px;
          line-height: 26px;
          letter-spacing: -0.25px;
          color: $color-dark-gray;
          margin-bottom: 0;
        }

        input:placeholder-shown + label {
          cursor: text;
          max-width: 66.66%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          transform-origin: left bottom;
          transform: translate(0, 88%) scale(1.5);
        }

        input::placeholder {
          opacity: 0;
        }

        input:not(:placeholder-shown) + label,
        input:focus + label {
          transform: translate(0, 0) scale(1);
        }
      }

      &__border {
        display: flex;
        align-items: center;
        height: 60px;
        width: 100%;
        &.--focused {
          border: "none";
        }
        &.--with-error {
          border: "none";
        }
        &.--disabled {
          border: none;
          label {
            color: $color-slate;
          }
        }
      }

      &__icon {
        cursor: pointer;
        img {
          width: 16px;
          height: 9px;
          transition: all 0.5s ease;
        }
        &.--open {
          img {
            transform: rotateX(180deg);
          }
        }
      }
      &__dropdown {
        position: absolute;
        width: 100%;
        max-height: 0px;
        overflow-x: hidden;
        overflow-y: auto;
        margin: 0;
        z-index: 9;
        background-color: $color-white;
        list-style: none;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.15);
        transition: height 0.5s ease-in-out;
        -ms-overflow-style: none;
        scrollbar-width: thin;
        &::-webkit-scrollbar {
          width: 5px;
          padding-right: 5px;
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 1px grey;
          border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: $color-dark-gray;
          border-radius: 5px;
        }
      }
      &__list-item {
        padding: 10px 20px;
        color: $color-slate;
        cursor: pointer;

        &:focus,
        &:hover {
          background-color: $color-off-white;
          color: $color-slate;
        }

        &.selected-item {
          background-color: $color-charcoal;
          color: $color-white;
          cursor: default;
          &:hover {
            background-color: $color-charcoal;
            color: $color-white;
          }
        }
      }
      &__list-value {
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.55px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      &__error-message {
        display: block;
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.38;
        letter-spacing: -0.3px;
        color: $color-error-red;
        margin-top: 10px;
      }
    }

    .dropdown--open {
      .select-dropdown__dropdown {
        max-height: 377px;
        padding: 16px 0 9px;
        z-index: 100;
      }
    }
  }
}
