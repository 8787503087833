@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";
@import "@/styles/mixins/breakpoint";

.buttonWrapper {
  :global {
    .button,
    .kf-react-button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      text-decoration: none;
      border: none;
      white-space: nowrap;
      padding: 0 40px;
      font-size: 18px;
      height: 57.56px;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      font-family: $font-medium;
      line-height: 1.33;
      letter-spacing: -0.35px;
      // transition: background-color 1.5s ease;
      &:hover {
        text-decoration: none;
      }

      &.disabled.compare-card-item__cart-button {
        opacity: 0.5;
        background-color: transparent;
        border: 1px solid #cdcdcd;
        color: #e6e6e6;
      }
      &.primary {
        background-color: $primary-blue;
        color: $color-white;
      }

      &.black {
        background-color: $color-charcoal;
        color: $color-white;
      }
      &.gray {
        background-color: $color-light-gray;
        color: $color-white;
      }
      &.ghost {
        background-color: transparent;
        border: 1px solid $color-slate;
        color: $color-slate;

        .loader {
          border: 2px solid rgb(219, 219, 219);
          border-left: 2px solid;
        }
      }

      &.secondary {
        background-color: transparent;
        border: 1px solid $color-white;
        color: $color-white;
      }

      &.large {
        font-size: 18px;
        letter-spacing: -0.35px;
        height: 60px;
      }

      &.small {
        font-size: 14px;
        letter-spacing: -0.25px;
        height: 40px;
        padding: 8px 31px;
      }

      &.flexible {
        width: 100%;
      }

      &.disabled {
        cursor: not-allowed;
        pointer-events: none;
        background-color: $color-light-gray;
        color: $color-white;
      }

      &.loading {
        cursor: not-allowed;
        pointer-events: none;
      }

      .loader {
        border: 2px solid #545454;
        border-left: 2px solid;
        animation: load 1s infinite linear;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }
    }
    .kf-react-button:hover {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
    .kf-react-button:focus,
    .kf-react-button:active {
      transform: scale(0.98);
    }

    @keyframes load {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    @media (max-width: 767px) {
      .button,
      .kf-react-button {
        padding: 0 30px;
        min-width: 103px;
      }
    }

    @media (hover: hover) {
      .button,
      .kf-react-button {
        &.primary {
          &:hover {
            background-color: #054974;
          }
        }
        &.black {
          &:hover {
            background-color: $color-black;
          }
        }
        &.gray {
          &:hover {
            background-color: $color-black;
          }
        }
        &.ghost {
          &:hover {
            background-color: $color-off-white-dark;
          }
        }
        &.load-more {
          &:hover {
            -webkit-transform: scale(0.9);
            transform: scale(0.9);
          }
        }
        &.secondary {
          &:hover {
            color: $color-black;
            background-color: $color-white;
          }
        }
        &.disabled {
          &:hover {
            pointer-events: none;
            background-color: $color-light-gray;
          }
        }
      }
    }
  }
}
