@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";

.findStoreLanding {
  :global {
    .find-a-store {
      max-width: 20in;
      margin: 0 auto;
      display: flex;
      align-items: center;
      &__container {
        width: 100%;
        padding: 0 2.6%;
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
      &__collapsible {
        .collapsible {
          &__header {
            span {
              font-family: $font-light;
              font-size: 1.625rem;
              font-weight: 300;
              line-height: 2.75rem;
              letter-spacing: -0.02519rem;
              color: $charcoal;
              font-style: "normal";
            }
          }
        }
      }

      &__content {
        width: 100%;
        max-width: 1380px;
      }

      &__flex-column {
        display: flex;
        flex-direction: column;
        gap: 30px;
      }

      &__label {
        font-size: 1.125rem;
        line-height: 1.625rem;
      }
      @media only screen and (min-width: 1920px) {
        &__container {
          padding: 75px 0 75px 0;
        }
        &__margin {
          margin: 60px 270px 126px 270px;
        }
        &__back-to-top {
          font-family: $font-regular;
          font-size: 18px;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: -0.5px;
          color: $color-slate;
          cursor: pointer;
          margin: 0 18px 40px 0;
          text-align: right;
        }
        &__flex-column {
          display: flex;
          flex-direction: column;
          gap: 30px;
        }
        &__section {
          display: flex;
          flex-direction: column;
          gap: 40px;

          &--result {
            gap: 0px;
          }

          &--no-result {
            border: 1px solid $color-light-gray;
            height: 14.9375rem;
            background: #fff;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }

        &__topic {
          font-family: $font-light;
          font-size: 2rem;
          font-weight: 300;
          line-height: 2.5rem;
          letter-spacing: -0.1rem;
          color: $color-slate;
        }
        &__selection {
          display: flex;
          flex-direction: row;
          gap: 20px;
        }
        &__horizontal-line {
          border: 1px solid $light-gray;
          margin-bottom: 1.875rem;
        }
        &__selected-city {
          font-family: $font-light;
          font-size: 2.8125rem;
          font-weight: 300;
          line-height: normal;
          letter-spacing: -0.04356rem;
          color: $dark-gray;
          font-style: "normal";
        }
        &__no-store-text {
          color: $color-slate;
          font-size: 1.625rem;
          line-height: 34px;
          font-weight: 300;
          font-family: $font-light;
        }
        &__title {
          font-family: $font-light;
          font-size: 1.25rem;
          font-weight: 400;
          line-height: "normal";
          letter-spacing: -0.01938rem;
          color: $dark-gray;
          font-style: "normal";
        }
        &__description {
          font-family: $font-light;
          font-size: 1rem;
          font-weight: 300;
          line-height: 1.75rem;
          letter-spacing: -0.0155rem;
          color: $dark-gray;
          font-style: "normal";
        }

        &__contact {
          font-family: $font-light;
          font-size: 1.25rem;
          font-weight: 300;
          line-height: 1.75rem;
          letter-spacing: -0.01938rem;
          color: $dark-gray;
          font-style: "normal";
        }

        &__select-dropdown {
          height: 3.75rem;
          width: 23.75rem;
        }
        &__separator {
          display: block;
          border: 1px solid $color-light-gray;
          margin: 0;
          padding: 0;
        }

        &__store-name {
          font-size: 20px;
          font-family: $font-regular;
          color: $color-slate;
          line-height: 29.4px;
          letter-spacing: -1.55%;
          margin: 0 0 20px 0;
          font-weight: 500;
        }

        &__store-owner-name,
        &__store-address-primary,
        &__store-address-secondary,
        &__store-address-zone,
        &__store-email,
        &__store-mobile,
        &__store-live-area {
          font-size: 16px;
          line-height: 28px;
          color: $color-dark-gray;
          margin-bottom: 5px;
          font-family: $font-regular;
          letter-spacing: -0.55px;
          font-weight: 300;
        }
        &__store-owner-name,
        &__store-address-primary,
        &__store-address-secondary {
          margin-bottom: 0;
        }

        &__store-email {
          color: #337ab7;
        }

        &__store-live-area {
          margin-bottom: 0;
        }

        &__store-city {
          margin-top: 58px;
          font-size: 45px;
          line-height: 66.15px;
          margin-bottom: 26px;
          font-family: $font-light;
          letter-spacing: -1.55%;
          color: $color-slate;
          font-weight: 300;
        }

        &__store-type {
          font-family: $font-light;
          font-size: 26px;
          font-weight: 300;
          line-height: 38.22px;
          letter-spacing: -1.55%;
          color: $color-charcoal;
        }

        &__store-bold {
          font-weight: 300;
          color: $slate;
        }

        &__store-details {
          gap: 40px;
          margin-left: 0;
          margin-bottom: 40px;
        }

        &__store-row {
          width: 399px;
        }
      }
      @media only screen and (max-width: 1920px) {
        &__container {
          padding: 75px 0 75px 0;
        }
        &__margin {
          margin: 60px 270px 126px 270px;
        }
        &__back-to-top {
          font-family: $font-regular;
          font-size: 18px;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: -0.5px;
          color: $color-slate;
          cursor: pointer;
          margin: 0 18px 40px 0;
          text-align: right;
        }
        &__flex-column {
          display: flex;
          flex-direction: column;
          gap: 30px;
        }
        &__section {
          display: flex;
          flex-direction: column;
          gap: 40px;

          &--result {
            gap: 0px;
          }

          &--no-result {
            border: 1px solid $color-light-gray;
            height: 14.9375rem;
            background: #fff;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }

        &__topic {
          font-family: $font-light;
          font-size: 2rem;
          font-weight: 300;
          line-height: 2.5rem;
          letter-spacing: -0.1rem;
          color: $color-slate;
        }
        &__selection {
          display: flex;
          flex-direction: row;
          gap: 20px;
        }
        &__horizontal-line {
          border: 1px solid $light-gray;
          margin-bottom: 1.875rem;
        }
        &__selected-city {
          font-family: $font-light;
          font-size: 2.8125rem;
          font-weight: 300;
          line-height: normal;
          letter-spacing: -0.04356rem;
          color: $dark-gray;
          font-style: "normal";
        }
        &__no-store-text {
          color: $color-slate;
          font-size: 1.625rem;
          line-height: 34px;
          font-weight: 300;
          font-family: $font-light;
        }
        &__title {
          font-family: $font-light;
          font-size: 1.25rem;
          font-weight: 400;
          line-height: "normal";
          letter-spacing: -0.01938rem;
          color: $dark-gray;
          font-style: "normal";
        }
        &__description {
          font-family: $font-light;
          font-size: 1rem;
          font-weight: 300;
          line-height: 1.75rem;
          letter-spacing: -0.0155rem;
          color: $dark-gray;
          font-style: "normal";
        }

        &__contact {
          font-family: $font-light;
          font-size: 1.25rem;
          font-weight: 300;
          line-height: 1.75rem;
          letter-spacing: -0.01938rem;
          color: $dark-gray;
          font-style: "normal";
        }

        &__select-dropdown {
          height: 3.75rem;
          width: 23.75rem;
        }
        &__separator {
          display: block;
          border: 1px solid $color-light-gray;
          margin: 0;
          padding: 0;
        }

        &__store-name {
          font-size: 20px;
          font-family: $font-regular;
          color: $color-slate;
          line-height: 29.4px;
          letter-spacing: -1.55%;
          margin: 0 0 20px 0;
          font-weight: 500;
        }

        &__store-owner-name,
        &__store-address-primary,
        &__store-address-secondary,
        &__store-address-zone,
        &__store-email,
        &__store-mobile,
        &__store-live-area {
          font-size: 16px;
          line-height: 28px;
          color: $color-dark-gray;
          margin-bottom: 5px;
          font-family: $font-regular;
          letter-spacing: -0.55px;
          font-weight: 300;
        }
        &__store-owner-name,
        &__store-address-primary,
        &__store-address-secondary {
          margin-bottom: 0;
        }

        &__store-email {
          color: #337ab7;
        }

        &__store-live-area {
          margin-bottom: 0;
        }

        &__store-city {
          margin-top: 58px;
          font-size: 45px;
          line-height: 66.15px;
          margin-bottom: 26px;
          font-family: $font-light;
          letter-spacing: -1.55%;
          color: $color-slate;
          font-weight: 300;
        }

        &__store-type {
          font-family: $font-light;
          font-size: 26px;
          font-weight: 300;
          line-height: 38.22px;
          letter-spacing: -1.55%;
          color: $color-charcoal;
        }

        &__store-bold {
          font-weight: 300;
          color: $slate;
        }
        &__store-details {
          gap: 40px;
          margin-left: 0;
          margin-bottom: 40px;
        }

        &__store-row {
          width: 399px;
        }
      }
      @media only screen and (max-width: 1440px) {
        &__container {
          padding: 75px 0 75px 0;
        }
        &__margin {
          margin: 60px 138px 126px 138px;
        }
        &__back-to-top {
          font-family: $font-regular;
          font-size: 18px;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: -0.5px;
          color: $color-slate;
          cursor: pointer;
          margin: 0 18px 40px 0;
          text-align: right;
        }
        &__flex-column {
          display: flex;
          flex-direction: column;
          gap: 30px;
        }
        &__section {
          display: flex;
          flex-direction: column;
          gap: 40px;

          &--result {
            gap: 0px;
          }

          &--no-result {
            border: 1px solid $color-light-gray;
            height: 14.9375rem;
            background: #fff;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }

        &__topic {
          font-family: $font-light;
          font-size: 2rem;
          font-weight: 300;
          line-height: 2.5rem;
          letter-spacing: -0.1rem;
          color: $color-slate;
        }
        &__selection {
          display: flex;
          flex-direction: row;
          gap: 20px;
        }
        &__horizontal-line {
          border: 1px solid $light-gray;
          margin-bottom: 1.875rem;
        }
        &__selected-city {
          font-family: $font-light;
          font-size: 2.8125rem;
          font-weight: 300;
          line-height: normal;
          letter-spacing: -0.04356rem;
          color: $dark-gray;
          font-style: "normal";
        }
        &__no-store-text {
          color: $color-slate;
          font-size: 1.625rem;
          font-weight: 300;
          font-family: $font-light;
          line-height: 34px;
        }
        &__title {
          font-family: $font-light;
          font-size: 1.25rem;
          font-weight: 400;
          line-height: "normal";
          letter-spacing: -0.01938rem;
          color: $dark-gray;
          font-style: "normal";
        }
        &__description {
          font-family: $font-light;
          font-size: 1rem;
          font-weight: 300;
          line-height: 1.75rem;
          letter-spacing: -0.0155rem;
          color: $dark-gray;
          font-style: "normal";
        }

        &__contact {
          font-family: $font-light;
          font-size: 1.25rem;
          font-weight: 300;
          line-height: 1.75rem;
          letter-spacing: -0.01938rem;
          color: $dark-gray;
          font-style: "normal";
        }

        &__select-dropdown {
          height: 3.75rem;
          width: 23.75rem;
        }
        &__separator {
          display: block;
          border: 1px solid $color-light-gray;
          margin: 0;
          padding: 0;
        }

        &__store-name {
          font-size: 20px;
          font-family: $font-regular;
          color: $color-slate;
          line-height: 29.4px;
          letter-spacing: -1.55%;
          margin: 0 0 20px 0;
          font-weight: 500;
        }

        &__store-address-primary,
        &__store-address-secondary,
        &__store-address-zone,
        &__store-email,
        &__store-mobile,
        &__store-live-area {
          font-size: 16px;
          line-height: 28px;
          color: $color-dark-gray;
          margin-bottom: 5px;
          font-family: $font-regular;
          letter-spacing: -0.55px;
          font-weight: 300;
        }
        &__store-address-primary,
        &__store-address-secondary {
          margin-bottom: 0;
        }

        &__store-email {
          color: #337ab7;
        }

        &__store-live-area {
          margin-bottom: 0;
        }

        &__store-city {
          margin-top: 58px;
          font-size: 45px;
          line-height: 66.15px;
          margin-bottom: 26px;
          font-family: $font-light;
          letter-spacing: -1.55%;
          color: $color-slate;
          font-weight: 300;
        }

        &__store-type {
          font-family: $font-light;
          font-size: 26px;
          font-weight: 300;
          line-height: 38.22px;
          letter-spacing: -1.55%;
          color: $color-charcoal;
        }

        &__store-bold {
          font-weight: 300;
          color: $slate;
        }
        &__store-details {
          gap: 40px;
          margin-left: 0;
          margin-bottom: 40px;
        }

        &__store-row {
          width: 344px;
        }
      }
      @media only screen and (max-width: 812px) {
        &__container {
          padding: 0;
        }
        &__margin {
          padding: 60px 32px 40px 32px;
          margin: 0;
        }
        &__back-to-top {
          font-family: $font-regular;
          font-size: 18px;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: -0.5px;
          color: $color-slate;
          cursor: pointer;
          margin: 0 18px 40px 0;
          text-align: right;
        }
        &__flex-column {
          display: flex;
          flex-direction: column;
          gap: 30px;
        }
        &__section {
          display: flex;
          flex-direction: column;
          gap: 40px;

          &--result {
            gap: 0px;
          }

          &--no-result {
            border: 1px solid $color-light-gray;
            height: 14.9375rem;
            background: #fff;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }

        &__topic {
          font-family: $font-light;
          font-size: 2rem;
          font-weight: 300;
          line-height: 2.5rem;
          letter-spacing: -0.1rem;
          color: $color-slate;
        }
        &__selection {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
        &__horizontal-line {
          border: 1px solid $light-gray;
          margin-bottom: 1.875rem;
        }
        &__selected-city {
          font-family: $font-light;
          font-size: 2.8125rem;
          font-weight: 300;
          line-height: normal;
          letter-spacing: -0.04356rem;
          color: $dark-gray;
          font-style: "normal";
        }
        &__no-store-text {
          color: $color-slate;
          font-size: 1.625rem;
          font-weight: 300;
          font-family: $font-light;
          line-height: 34px;
        }
        &__title {
          font-family: $font-light;
          font-size: 1.25rem;
          font-weight: 400;
          line-height: "normal";
          letter-spacing: -0.01938rem;
          color: $dark-gray;
          font-style: "normal";
        }
        &__description {
          font-family: $font-light;
          font-size: 1rem;
          font-weight: 300;
          line-height: 1.75rem;
          letter-spacing: -0.0155rem;
          color: $dark-gray;
          font-style: "normal";
        }

        &__contact {
          font-family: $font-light;
          font-size: 1.25rem;
          font-weight: 300;
          line-height: 1.75rem;
          letter-spacing: -0.01938rem;
          color: $dark-gray;
          font-style: "normal";
        }

        &__select-dropdown {
          height: 3.75rem;
          width: 100%;
        }
        &__separator {
          display: block;
          border: 1px solid $color-light-gray;
          margin: 0;
          padding: 0;
        }

        &__store-name {
          font-size: 20px;
          font-family: $font-regular;
          color: $color-slate;
          line-height: 29.4px;
          letter-spacing: -1.55%;
          margin: 0 0 20px 0;
          font-weight: 500;
        }

        &__store-address-primary,
        &__store-address-secondary,
        &__store-address-zone,
        &__store-email,
        &__store-mobile,
        &__store-live-area {
          font-size: 16px;
          line-height: 28px;
          color: $color-dark-gray;
          margin-bottom: 5px;
          font-family: $font-regular;
          letter-spacing: -0.55px;
          font-weight: 300;
        }
        &__store-address-primary,
        &__store-address-secondary {
          margin-bottom: 0;
        }

        &__store-email {
          color: #337ab7;
        }

        &__store-live-area {
          margin-bottom: 0;
        }

        &__store-city {
          margin-top: 45px;
          font-size: 45px;
          line-height: 66.15px;
          margin-bottom: 26px;
          font-family: $font-light;
          letter-spacing: -1.55%;
          color: $color-slate;
          font-weight: 300;
        }

        &__store-type {
          font-family: $font-light;
          font-size: 26px;
          font-weight: 300;
          line-height: 38.22px;
          letter-spacing: -1.55%;
          color: $color-charcoal;
        }

        &__store-bold {
          font-weight: 300;
          color: $slate;
        }
        &__store-details {
          gap: 40px;
          margin-left: 0;
          margin-bottom: 40px;
        }

        &__store-row {
          width: 344px;
        }
      }
      @media only screen and (min-device-width: 375px) and (max-device-width: 480px) {
        &__container {
          padding: 0;
        }
        &__margin {
          margin: 0;
          padding: 40px 18px 40px 18px;
        }
        &__back-to-top {
          font-family: $font-regular;
          font-size: 18px;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: -0.5px;
          color: $color-slate;
          cursor: pointer;
          margin: 0 18px 40px 0;
          text-align: right;
        }
        &__flex-column {
          display: flex;
          flex-direction: column;
          gap: 30px;
        }
        &__section {
          display: flex;
          flex-direction: column;
          gap: 30px;

          &--result {
            gap: 0px;
          }

          &--no-result {
            border: 1px solid $color-light-gray;
            height: 14.9375rem;
            background: #fff;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }

        &__topic {
          font-family: $font-light;
          font-size: 2rem;
          font-weight: 300;
          line-height: 2.5rem;
          letter-spacing: -0.1rem;
          color: $color-slate;
        }
        &__selection {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
        &__horizontal-line {
          border: 1px solid $light-gray;
          margin-bottom: 1.875rem;
        }
        &__selected-city {
          font-family: $font-light;
          font-size: 2.8125rem;
          font-weight: 300;
          line-height: normal;
          letter-spacing: -0.04356rem;
          color: $dark-gray;
          font-style: "normal";
        }
        &__no-store-text {
          color: $color-slate;
          font-size: 1.625rem;
          font-weight: 300;
          font-family: $font-light;
          line-height: 34px;
        }
        &__title {
          font-family: $font-light;
          font-size: 1.25rem;
          font-weight: 400;
          line-height: "normal";
          letter-spacing: -0.01938rem;
          color: $dark-gray;
          font-style: "normal";
        }
        &__description {
          font-family: $font-light;
          font-size: 1rem;
          font-weight: 300;
          line-height: 1.75rem;
          letter-spacing: -0.0155rem;
          color: $dark-gray;
          font-style: "normal";
        }

        &__contact {
          font-family: $font-light;
          font-size: 1.25rem;
          font-weight: 300;
          line-height: 1.75rem;
          letter-spacing: -0.01938rem;
          color: $dark-gray;
          font-style: "normal";
        }

        &__select-dropdown {
          height: 3.75rem;
          width: 100%;
        }
        &__separator {
          display: block;
          border: 1px solid $color-light-gray;
          margin: 0;
          padding: 0;
        }

        &__store-name {
          font-size: 20px;
          font-family: $font-regular;
          color: $color-slate;
          line-height: 29.4px;
          letter-spacing: -1.55%;
          margin: 0 0 20px 0;
          font-weight: 500;
        }

        &__store-address-primary,
        &__store-address-secondary,
        &__store-address-zone,
        &__store-email,
        &__store-mobile,
        &__store-live-area {
          font-size: 16px;
          line-height: 28px;
          color: $color-dark-gray;
          margin-bottom: 5px;
          font-family: $font-regular;
          letter-spacing: -0.55px;
          font-weight: 300;
        }
        &__store-address-primary,
        &__store-address-secondary {
          margin-bottom: 0;
        }

        &__store-email {
          color: #337ab7;
        }

        &__store-live-area {
          margin-bottom: 0;
        }

        &__store-city {
          margin-top: 45px;
          font-size: 45px;
          line-height: 66.15px;
          margin-bottom: 26px;
          font-family: $font-light;
          letter-spacing: -1.55%;
          color: $color-slate;
          font-weight: 300;
        }

        &__store-type {
          font-family: $font-light;
          font-size: 26px;
          font-weight: 300;
          line-height: 38.22px;
          letter-spacing: -1.55%;
          color: $color-charcoal;
        }

        &__store-bold {
          font-weight: 300;
          color: $slate;
        }

        &__store-details {
          gap: 40px;
          margin-left: 0;
          margin-bottom: 40px;
        }

        &__store-row {
          width: 286px;
        }
      }
    }
  }
}
