.promoBannerWrapper {
  :global {
    .promo-banner {
      .cmp-promo__description {
        a {
          text-decoration: underline;
          margin-left: 2px;
          cursor: pointer;
        }
      }
    }
    @media print {
      .carousel-promo-banner {
        display: none !important;
      }
    }
  }
}
