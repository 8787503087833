@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";

.blogLanding {
  :global {
    .blog-page {
      display: block;
      max-width: 1920px;
      margin: 0 auto;
      &__tab-list {
        display: flex;
        height: 65px;
        margin: 0;
        padding: 0;
        justify-content: center;
        align-items: flex-end;
        list-style-type: none;
        border-bottom: 1px solid $color-light-gray;
      }
      &__tab-list-item {
        font-size: 20px;
        font-style: normal;
        line-height: 28px; /* 140% */
        letter-spacing: -0.4px;
        cursor: pointer;
        color: $color-dark-gray;
        font-weight: 500;
        white-space: nowrap;
        padding: 0 30px 15px;
      }
      &__tab-list-active {
        color: $color-charcoal;
        border-bottom: 4px solid $color-charcoal;
      }
      &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        font-family: $font-regular;
        font-style: normal;
        color: $color-black;
      }
      &__title {
        font-weight: 400;
        font-size: 2.625rem;
        line-height: 120%;
        letter-spacing: -0.03em;
        margin-top: 4.5rem;
      }
      &__view-all {
        font-size: 1rem;
        line-height: 150%;
        color: $color-black;
        border-bottom: 0.0625rem solid $color-black;
        text-decoration: none;
      }
      &__view-all-center {
        text-align: center;
        margin: 2rem 0;

        a {
          font-size: 1rem;
          line-height: 150%;
          color: $color-black;
          border-bottom: 0.0625rem solid $color-black;
          text-decoration: none;
        }
      }

      &__list-wrapper {
        margin: 100px;
      }

      &__list-content {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        padding: 0;
        margin: 0 -30px 0 0;
      }

      &__carousel {
        margin: 70px 0;
      }

      &__content {
        padding: 0 50px 60px;
        overflow-x: auto;
        scroll-behavior: smooth;

        .os-scrollbar-horizontal {
          .os-scrollbar-track {
            border-radius: 0;
            background-color: $color-light-gray !important;

            .os-scrollbar-handle {
              border-radius: 0;
              background-color: $color-dark-gray !important;
            }
          }
        }
      }

      &__heading {
        font-family: $font-light;
        color: $slate;
        font-size: 60px;
        font-style: normal;
        font-weight: 300;
        line-height: 68px; /* 113.333% */
        letter-spacing: -3.1px;
        margin-left: 50px;
        margin-bottom: 50px;
      }

      &__viewAllLink {
        font-family: $font-light;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 140% */
        letter-spacing: -0.4px;
        color: $slate;
        margin: 1.875rem 0 0 3.75rem;
        text-decoration-line: underline;
        cursor: pointer;
      }

      &__slider {
        display: inline-flex;
        padding: 0;
        list-style-type: none;
        margin: 0;
      }
      &__article-card-wrapper {
        width: 417px;
        height: 100%;
        margin-right: 5px;
        &.remove-mr {
          &:last-child {
            margin-right: 0;
          }
        }

        .hide-last-child {
          display: none;
        }
      }
      &__article-card-wrapper-list {
        flex: 0 0 33.33%;
        max-width: 33.3%;
        padding-right: 5px;
        margin: 15px 0px;
      }
      .most-popular-section {
        display: inline-flex;
        width: 19.6875rem;
        flex-direction: column;

        &.top {
          padding: 3rem 2.75rem 0;
          margin: 0;
          width: 100%;
        }
        .most-popular-title {
          font-family: $font-regular;
          font-size: 1.75rem;
          font-weight: 400;
          line-height: 2.5625rem;
          letter-spacing: -0.03em;
          color: $color-black;
          margin-bottom: 1.5rem;
        }
        .links-section {
          font-family: $font-regular;
          font-size: 1rem;
          line-height: 1.5rem;
          letter-spacing: -0.03em;
          color: $color-black;
          margin-bottom: 1.5rem;

          &:last-child {
            margin-bottom: 0;
          }

          a {
            color: $color-black;
            text-decoration: underline;
            &:hover {
              color: $blue;
            }
          }
        }
      }
      &__content.latestPopular {
        margin: 3.5rem 6.875rem 4.5rem;
        overflow: hidden;
      }

      ul {
        list-style-type: none;
        padding: 0;
      }

      &__footer-div {
        display: flex;
        justify-content: space-between;
      }

      @media (max-width: 1600px) {
        &__article-card-wrapper-list {
          flex: 0 0 33.33%;
          max-width: 33.33%;
        }
      }

      @media (max-width: 1023px) {
        &__tab-list {
          padding: 0 18px;
          justify-content: start;
          overflow-x: auto;
          -ms-overflow-style: none;
          scrollbar-width: none;
          &::-webkit-scrollbar {
            display: none;
          }
        }

        &__tab-list-item {
          padding: 0 15px 20px;
        }

        &__carousel {
          margin: 50px 0;
        }

        &__content {
          padding: 0 32px 30px;
        }

        &__heading {
          font-size: 40px;
          line-height: 46px;
          letter-spacing: -2.5px;
          margin-left: 32px;
          margin-bottom: 40px;
        }

        &__article-card-wrapper {
          width: 308px;
        }

        &__list-wrapper {
          margin: 32px;
        }

        &__article-card-wrapper-list {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }

      @media (max-width: 767px) {
        &__list-wrapper {
          margin: 30px 18px;
        }

        &__list-content {
          margin: 25px 0 0;
        }

        &__article-card-wrapper-list {
          flex: 0 0 100%;
          max-width: 100%;
          padding-right: 0;
          margin: 15px 0;
        }
        &__footer-div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          gap: 25px;
        }
      }
    }
  }
}
