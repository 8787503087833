@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";

.otpVerification {
  :global {
    .otp-popup {
      width: 500px;
      padding: 0 50px;
      font-family: $font-light;

      form {
        margin: 0;
      }

      .input-field {
        &__value {
          label {
            font-size: 12px;
          }
        }
      }

      &__input-field-div-1 {
        width: 17.5%;
      }

      &__input-field-div-2 {
        width: 82.5%;
      }

      &__input-div {
        display: flex;
        flex-direction: row;
        gap: 20px;
        width: 100%;
      }

      &__otp-popup-button {
        margin: 30px 0 ;
        font-family: $font-medium;
        background-color: $color-charcoal !important;
        height: 50px;
        @media screen and (max-width: 812px) {
          margin: 30px 0 30px;
        }
      }

      &__button {
        height: 50px !important;
      }

      &__social-login {
        padding: 0;
        margin: 0;

        &-button {
          font-family: $font-medium;
          font-size: 14px;
          line-height: 1.71;
          letter-spacing: -0.25px;
          text-align: center;
          color: $color-charcoal;
          margin-bottom: 20px;
        }
      }

      &__error-box {
        margin-top: 0;
        margin-bottom: 15px;

        .error-msg {
          font-family: $font-medium;
          font-size: 16px;
          text-align: left;
          color: $color-error-red;

          a {
            color: $color-error-red;
            text-decoration: underline;
            cursor: pointer;
          }

          p {
            color: $color-error-red;
          }
        }
      }

      &__footer {
        font-family: $font-regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
        letter-spacing: -0.3px;
        color: $color-dark-gray;

        a {
          font-family: $font-regular;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: -0.3px;
          text-decoration-line: underline;
          color: $color-dark-gray;
          display: inline-block;

          &:hover {
            text-decoration: underline;
            color: $color-black;
          }

          cursor: pointer;
        }

        p {
          font-size: 14px !important;
          letter-spacing: -0.3px !important;
          color: $color-dark-gray;
        }
      }

      &__please-try-again {
        font-family: $font-regular;
        letter-spacing: -0.3px !important;
      }

      &__withOTP {
        font-family: $font-regular;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.818px;
        text-transform: uppercase;
        color: $color-slate;
        margin-bottom: 35px;
      }
      &__title {
        font-family: "Helvetica Now Text W05 Regular";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.818px;
        text-transform: uppercase;
        text-wrap: wrap;
        width: 415px;
        color: $color-slate;
        line-height: 29.4px;
        @media screen and (max-width: 812px) {
          width: 100%;
          margin-bottom: 0;
        }
      }
      &__updateNumberDiv {
        display: flex;
        justify-content: center;
      }

      &__updateNumber {
        font-family: $font-regular;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 140% */
        letter-spacing: -0.35px;
        margin: 0;
        color: $color-slate;
        text-decoration-line: underline;
        text-underline-offset: 4px;
        cursor: pointer;
      }
      .resend-disabled {
        font-family: $font-regular;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 140% */
        letter-spacing: -0.35px;
        margin: 0;
        color: #757575;
        text-decoration-line: underline;
        text-underline-offset: 4px;
        pointer-events: none;
        opacity: .5;
      }

      &__backToSignIn {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 35px;
        cursor: pointer;
      }

      &__description {
        font-family: $font-medium;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
        letter-spacing: -0.55px;
        color: $color-dark-gray;
        margin: 15px 0 30px;

        p {
          color: $color-dark-gray;
          display: inline-block;
        }
      }
      &__mobileprint {
        display: block;
      }
      &__otpDigitsDiv {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        column-gap: 15px;
      }
      .otp-change-number {
        font-family: $font-regular;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: -0.55px;
        text-decoration: underline;
        color: $color-dark-gray;
        cursor: pointer;
      }
      &__otpDigit {
        width: 54px;

        @media screen and (max-width: 812px) {
          width: 66px;
        }

        @media screen and (max-width: 576px) {
          width: 44px;
        }

        .input-field {
          margin: 0;
        }

        .input-field__border {
          height: 74px;

          @media only screen and (max-width: 812px) {
            height: 84px;
          }

          @media only screen and (max-width: 576px) {
            height: 64px;
            padding: 5px 10px;
          }

          .input-field__value {
            input {
              font-size: 24px;
              line-height: 30px;
              letter-spacing: -0.9px;
              text-align: center;
            }
          }
        }
      }

      &__otpSendText {
        font-family: $font-regular;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: -0.5px;
        color: $color-dark-gray;
        margin: 0 2px 0 0;
      }

      &__timerDiv {
        display: flex;
        flex-direction: row;
        font-family: $font-regular;
        width: 100%;
        justify-content: center;
        margin: 0 0 15px 0;
        color: $color-dark-gray;
        letter-spacing: -0.4px;
        // @media screen and (max-width: 812px) {
        //   margin-bottom: 30px;
        // }
        .expireotp {
          margin-right: 5px;
        }
      }

      &__otpErrorDiv {
        display: flex;
        flex-direction: row;
        gap: 5px;
        width: 100%;
        justify-content: center;
        margin: 15px 0 0 ;
        color: $color-error-red;
        font-family: $font-regular;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
        letter-spacing: -0.4px;
      }

      &__otpSendTextDiv {
        display: flex;
        flex-direction: row;
        gap: 5px;
        width: 100%;
        justify-content: center;
        margin: 0 0 15px;
        align-items: flex-end;
      }

      &__show-margin {
        margin-top: 10px;
      }
      &__buttonDiv {
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-items: stretch;
      }

      &__button {
        width: 100%;
      }

      &__add-margin {
        margin-top: 10px;
        margin-bottom: 40px;
        text-align: center;
      }
      @media screen and (max-width: 812px) {
        padding: 0;
      }
    }

    @media screen and (max-width: 812px) {
      .otp-popup {
        width: 100%;
        margin: 0;
      }

      .modal-popup .modal-popup-content {
        .close-button {
          right: 32px;
        }
      }
    }

    @media screen and (max-width: 576px) {
      .otp-popup {
        padding: 0;
        width: 100%;
        display: flex;
        flex-direction: column;

        &__footer {
          position: initial;

          a {
            font-size: 13px;
          }
        }

        &__otpDigit {
          width: 44px;
        }

        &__otpDigitsDiv {
          display: flex;
          width: 100%;
          justify-content: center;
          column-gap: 15px;
        }
      }

      .modal-popup .modal-popup-content {
        .close-button {
          right: 18px;
        }
      }
    }

    // Change Phone number css

    .change-number-popup {
      width: 500px;
      padding: 0 50px;
      font-family: $font-light;

      &__footer {
        font-family: $font-regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.3px;
        color: $color-dark-gray;

        a {
          font-family: $font-regular;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: -0.3px;
          text-decoration-line: underline;
          color: $color-dark-gray;
          display: inline-block;

          &:hover {
            text-decoration: underline;
            color: $color-dark-gray;
          }

          cursor: pointer;
        }

        p {
          font-size: 14px !important;
          letter-spacing: -0.3px !important;
          color: $color-dark-gray;
        }
      }
      &__description {
        font-family: $font-regular;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
        margin: 0 0 30px;

        p {
          color: $color-dark-gray;
        }
      }
      &__title {
        font-family: $font-regular;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.818px;
        text-transform: uppercase;
        color: $color-slate;
        margin-bottom: 15px;
      }

      &__phoneDiv {
        display: flex;
        flex-direction: row;
        width: 100%;
      }

      &__phoneNumber {
        width: 100%;
      }

      &__wrap-button {
        margin: 20px 0 20px;
        font-family: $font-medium;
        background-color: $color-charcoal !important;
        height: 50px;
      }

      &__button {
        height: 50px !important;
        letter-spacing: -0.35px;
      }

      .input-field {
        margin-bottom: 10px;
        &__border {
          border-radius: 0 4px 4px 0;
        }
      }
      &__countryIcon {
        width: 70px;
        .input-field {
          &__border {
            border-radius: 4px 0 0 4px;
            border: solid 1px $color-dark-gray;
            border-right: none;
          }
        }
      }
    }

    @media screen and (max-width: 812px) {
      .change-number-popup {
        width: 100%;
        margin: 0;
        padding: 0 32px;
      }
    }

    @media screen and (max-width: 576px) {
      .change-number-popup {
        padding: 0;
        margin: -15px 0 0 -12px;
        width: calc(100% + 22px);
        display: flex;
        flex-direction: column;

        &__footer {
          position: initial;

          a {
            font-size: 13px;
          }
        }
      }
    }
  }
}
